import { InputAdornment, makeStyles, TextField, withStyles } from '@material-ui/core';
import React, { FC, KeyboardEvent } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  value: string;
  id?: string;
  type?: 'password' | 'date' | 'email' | 'search' | 'number';
  isCurrency?: boolean;
  label?: string;
  placeholder?: string;
  inputClassName?: string;
  rows?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  min?: number;
  onChange?: (value: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (value: string) => void;
};

const MegTextField = withStyles({
  root: {
    background: '#ffffff',
    borderRadius: '0.5rem',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#8acc9c',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  input: {
    color: '#333333',
  },
  disabled: {
    backgroundColor: '#e0e0e0',
    color: '#969696',
  },
  label: {
    color: '#333333',
  },
}));

export const AdminInput: FC<Props> = ({
  className,
  value,
  id,
  isCurrency,
  label,
  placeholder,
  inputClassName,
  rows = 1,
  disabled = false,
  autoFocus = false,
  type,
  min,
  onChange,
  onKeyDown,
  onBlur,
}) => {
  const classes = useStyles();

  const handleChange = onChange
    ? (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(e.target.value);
      }
    : undefined;

  const handleBlur = onBlur
    ? (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onBlur(e.target.value);
      }
    : undefined;

  const handleKeyDown = onKeyDown
    ? (e: KeyboardEvent<HTMLDivElement>) => {
        onKeyDown(e);
      }
    : undefined;

  return (
    <MegTextField
      onChange={handleChange}
      InputProps={{
        classes: {
          input: `${classes.input} ${inputClassName}`,
          disabled: classes.disabled,
        },
        startAdornment: isCurrency ? <InputAdornment position="start">￥</InputAdornment> : undefined,
        inputProps: { min },
      }}
      id={id}
      value={value}
      size="small"
      variant="outlined"
      label={label}
      InputLabelProps={{ classes: { root: classes.label } }}
      placeholder={placeholder}
      multiline={rows > 1}
      rows={rows}
      disabled={disabled}
      autoFocus={autoFocus}
      className={className}
      type={type}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  );
};
