const getFocusables = (element: any) => {
  return element.tabIndex >= '0';
};

export const focusables = () => {
  const elements = document.querySelectorAll('input, button, select, textarea, a[href]');
  return Array.prototype.filter.call(elements, (element) => getFocusables(element));
};

/**
 * フォーカス可能な次の要素を返してくれる関数
 * @param el 現在フォーカス中の要素
 */
export const findNextTabStop = (e: EventTarget) => {
  const list = focusables();
  const index = list.indexOf(e);
  if (index + 1 < list.length) return list[index + 1];
  return list[0];
};
