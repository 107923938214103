import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../props/classname';
import { AdminButton } from './adminButton';

type Props = ClassName & {
  positiveText: string;
  negativeText: string;
  isPositiveDisabled?: boolean;
  isNegativeDisabled?: boolean;
  autoFocus?: boolean;
  onPositiveClick?: () => void;
  onNegativeClick?: () => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '50px',
    justifyContent: 'space-around',
  },
  button: {
    width: '8rem',
  },
}));

export const AdminButtonGroup: FC<Props> = ({
  className,
  positiveText,
  negativeText,
  isPositiveDisabled,
  isNegativeDisabled,
  autoFocus,
  onPositiveClick,
  onNegativeClick,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <AdminButton
        className={classes.button}
        color="negative"
        text={negativeText}
        onClick={onNegativeClick}
        disabled={isNegativeDisabled}
      />
      <AdminButton
        className={classes.button}
        color="positive"
        text={positiveText}
        onClick={onPositiveClick}
        disabled={isPositiveDisabled}
        autoFocus={autoFocus}
      />
    </div>
  );
};
