import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  open: boolean;
  onClose: () => void;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
};

interface StylesProps {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}

const useStyles = makeStyles((theme) => ({
  paper: (props: StylesProps) => ({
    width: props.width ? props.width : '60%',
    height: props.height ? props.height : '35%',
    borderRadius: '1rem',
    '@media (max-width: 1024px)': {
      height: '60%',
    },
  }),
}));

export const SettingsDialog: FC<Props> = ({ className, open, onClose, width, height, children }) => {
  const classes = useStyles({ width, height });

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: `${classes.paper} ${className}` }}>
      {children}
    </Dialog>
  );
};
